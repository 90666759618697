import Link from '@/components/Link';
import { gikClassPrefix } from '@gik/core/constants';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { Decoder } from '@gik/ui/Decoder';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { Image } from '@gik/ui/Image';
import { AnimatedDivBone, ParagraphBone } from '@gik/ui/SkeletonLoader';
import React from 'react';

export type ProductTileDirection = 'row' | 'column';

export interface IProductTileProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'children'> {
  imageUrl?: string;
  toolbar?: React.ReactNode;
  containImage?: boolean;
  direction?: ProductTileDirection;
  title?: string;
  href?: string;
  skeleton?: boolean;
  description?: string;
}

function ProductTileComp({
  className,
  imageUrl,
  toolbar,
  containImage,
  direction = 'column',
  title,
  href,
  skeleton,
  description,
  ...otherProps
}: IProductTileProps): React.ReactElement {
  const [mouseHovering, setMouseHovering] = React.useState<boolean>(false);

  const bem = useBemCN('product-tile');

  function onMouseEnter() {
    setMouseHovering(true);
  }

  function onMouseLeave() {
    setMouseHovering(false);
  }

  const image = (
    <>
      {skeleton ? (
        <AnimatedDivBone className={`${gikClassPrefix}-bg-image`} />
      ) : (
        <figure {...bem('figure')}>
          <Image alt="thumbnail" src={imageUrl} />
        </figure>
      )}
    </>
  );

  const innerComp = (
    <div
      {...bem(
        null,
        [
          { row: direction === 'row' },
          { col: direction === 'column' },
          { hover: mouseHovering },
          { skeleton },
          { description: description?.length > 0 },
          { 'contain-image': containImage },
        ],
        className
      )}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...otherProps}
    >
      <div {...bem('box-shadow')} />
      {toolbar && <div {...bem('toolbar')}>{toolbar}</div>}
      {href ? (
        <div {...bem('image-wrapper')}>
          <a {...bem('link')}>{image}</a>
        </div>
      ) : (
        <div {...bem('image-wrapper')}>{image}</div>
      )}
      <main>
        {title && (
          <div {...bem('title')}>
            <Decoder text={title} />
          </div>
        )}
        {skeleton && (
          <div {...bem('title')}>
            <ParagraphBone words={3} />
          </div>
        )}
        {description && (
          <a {...bem('description')}>
            {skeleton ? (
              <ParagraphBone words={12} />
            ) : (
              <p>
                <HTMLParser rawHtml={description} />
              </p>
            )}
          </a>
        )}
      </main>
    </div>
  );

  if (href) {
    return (
      <Link style={{ width: '100%' }} href={href}>
        {innerComp}
      </Link>
    );
  }

  return innerComp;
}

export const ProductTile = React.memo(ProductTileComp, (prevProps, nextProps) => {
  return (
    prevProps.imageUrl === nextProps.imageUrl &&
    prevProps.toolbar === nextProps.toolbar &&
    prevProps.direction === nextProps.direction &&
    prevProps.description === nextProps.description
  );
});
