import type { Hit } from '@algolia/client-search';
import { openPremiumPageThankYouModal } from '@gik/checkout/components/PremiumPageUpgradeThankYou/PremiumPageUpgradeThankYou';
import { openPremiumPageUpgradeModal } from '@gik/checkout/utils/openPremiumPageUpgradeModal';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { nativeShareSupported } from '@gik/core/utils/browser';
import noop from '@gik/core/utils/noop';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { isInkindPageOrganizer, isInkindPageOwner } from '@gik/inkind-page/utils/inkindPagePermissions';
import type { InkindAlgoliaObject } from '@gik/search/components';
import { Button } from '@gik/ui/Button';
import ShareAlt from '@gik/ui/SvgIcon/GikIcons/ShareAlt';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { useInkindAPIOrAlgolia } from '@gik/user-profile/hooks/useInkindAPIOrAlgolia';
import StarOutlineIcon from '@heroicons/react/outline/StarIcon';
import TrashIcon from '@heroicons/react/outline/TrashIcon';
import HeartIcon from '@heroicons/react/solid/HeartIcon';
import StarIcon from '@heroicons/react/solid/StarIcon';
import React from 'react';
import { openShareInkindModal } from '../ShareInkindModal/openShareInkindModal';

export type InkindPageTileToolbarProps = {
  inkindPage: InkindPageAPIModel | Hit<InkindAlgoliaObject>;
  onRemove?(inkindPageOrAlgolia: InkindPageAPIModel | Hit<InkindAlgoliaObject>): void | Promise<void>;
  revalidateInkindPages?(): void;
} & UIComponent;

const blockName = 'inkind-page-tile-toolbar';

export function InkindPageTileToolbarComp({
  onRemove,
  inkindPage: inkindPageOrAlgolia,
  revalidateInkindPages = noop,
}: InkindPageTileToolbarProps): JSX.Element {
  const bem = useBemCN(blockName);

  const { ownerId, slug, title, routeId, groupId, isPremium, shortUrl, url, organizers } =
    useInkindAPIOrAlgolia(inkindPageOrAlgolia);

  const canEdit = useInkindCan('manage', routeId, groupId);
  const userId = useUserStore(store => store.id);
  const isOwner = isInkindPageOwner(ownerId, userId);
  const isOrganizer = isInkindPageOrganizer({ organizers }, userId);

  const onClickRemove = async function (e: React.MouseEvent) {
    e.preventDefault();
    e.stopPropagation();

    onRemove(inkindPageOrAlgolia);
  };

  return (
    <section {...bem()}>
      <div className="tw-flex tw-justify-center">
        <Button
          type="button"
          variant="default-plain"
          circle
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            openShareInkindModal(
              {
                isOrganizer: canEdit,
                pageTitle: title,
                pageShortUrl: shortUrl,
                inkindRouteId: routeId,
                emailInvitationsButton: canEdit,
                moreOptionsButton: nativeShareSupported(),
              },
              'profilePage',
              url,
              canEdit
            );
          }}
          tooltip="Share page"
        >
          <SvgIcon Icon={ShareAlt} size="xl" name="share" />
        </Button>
      </div>
      <div className="tw-flex tw-justify-center">
        {canEdit && (
          <Button
            type="button"
            variant="default-plain"
            circle
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              if (!isPremium) {
                openPremiumPageUpgradeModal({
                  initiatedOn: 'profilePage',
                  variant: 'generic',
                  inkindRouteId: routeId,
                  inkindSlug: slug,
                  onPurchase: revalidateInkindPages,
                });
              } else {
                openPremiumPageThankYouModal();
              }
            }}
            tooltip={isPremium ? 'Premium page' : 'Upgrade page'}
          >
            <SvgIcon Icon={isPremium ? StarIcon : StarOutlineIcon} name="premium" />
          </Button>
        )}
      </div>
      <div className="tw-flex tw-justify-center">
        <Button
          type="button"
          variant="default-plain"
          circle
          onClick={onClickRemove}
          disabled={isOrganizer}
          preventClickWhenDisabled
          tooltip={
            isOwner ? 'Deactivate page' : isOrganizer ? 'Only a Page Owner can deactivate a Page' : 'Unfollow page'
          }
        >
          <SvgIcon Icon={canEdit ? TrashIcon : HeartIcon} name="close" />
        </Button>
      </div>
    </section>
  );
}

export const InkindPageTileToolbar = withComponentErrorBoundary(InkindPageTileToolbarComp);
