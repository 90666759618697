import { useWishlistProducts } from '@gik/api/inkinds/wishlistProducts';
import { auth } from '@gik/auth';
import { optimisticUpdate } from '@gik/core/api';
import { dotnetApi } from '@gik/core/api/ky/dotnetApi';
import { useUserStore } from '@gik/core/store/UserStore';
import { UI } from '@gik/ui/UIManager';
import type React from 'react';

interface RemoveFromWishlistButtonProps {
  itemId: number;
  inkindRouteId: string;
  child(onClick: (ev: React.MouseEvent<HTMLElement>) => void): React.ReactElement;
}

export function RemoveFromWishlistButton({
  itemId,
  inkindRouteId,
  child,
}: RemoveFromWishlistButtonProps): React.ReactElement {
  const wishlistProductUrl = `inkinds/${inkindRouteId}/wishlistProducts/${itemId}`;
  const userStore = useUserStore();
  const userId = userStore.id;

  const { data: wishListProducts, mutate: refreshWishListProducts } = useWishlistProducts(inkindRouteId);

  const onClick = () => {
    if (!wishListProducts) {
      return;
    }
    if (!userId) {
      auth.signin();
      return;
    }

    optimisticUpdate(
      wishListProducts.filter(product => product.id !== itemId),
      null,
      refreshWishListProducts,
      async () => {
        try {
          await dotnetApi.delete(wishlistProductUrl);
          UI.notifySuccess('Item successfully removed from wishlist');
        } catch (err) {
          UI.notifyError('Failed to remove item from wishlist');
        }
      }
    );
  };

  return child(onClick);
}
