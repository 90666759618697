import { useUserOwnedOrOrganizingPages } from '@gik/api/inkinds/userPages';
import { useUserStore } from '@gik/core/store/UserStore';
import bemBlock from '@gik/core/utils/bemBlock';
import { AddToFavouritesButton } from '@gik/ui/gik/AddToFavouritesButton';
import { AddToWishlistPopover } from '@gik/ui/gik/AddToWishlistPopover';
import { SvgIcon } from '@gik/ui/SvgIcon/SvgIcon';
import { Tooltip } from '@gik/ui/Tooltip';
import HeartOutlineIcon from '@heroicons/react/outline/HeartIcon';
import MinusIcon from '@heroicons/react/outline/MinusIcon';
import PlusIcon from '@heroicons/react/outline/PlusIcon';
import TrashIcon from '@heroicons/react/outline/TrashIcon';
import HeartIcon from '@heroicons/react/solid/HeartIcon';
import React from 'react';
import { RemoveFromWishlistButton } from '../RemoveFromWishlistButton';

export interface IProductTileToolbarProps {
  inkindRouteId?: string;
  itemId?: number;
  hasRemoveFromCurrentPageButton?: boolean;
}

export function ProductTileToolbar({
  itemId,
  inkindRouteId,
  hasRemoveFromCurrentPageButton,
}: IProductTileToolbarProps): React.ReactElement {
  const userStore = useUserStore();
  const { data: inkindPages } = useUserOwnedOrOrganizingPages(userStore.id);

  // TODO: share code with ProductActions
  const addToWishlist = Boolean(
    inkindPages?.length !== 1 || !inkindPages[0].wishlistProductIds?.some(id => id === itemId)
  );

  // TODO: add translations
  const bem = bemBlock('product-tile-toolbar');
  return (
    <div
      className={bem()}
      onClick={ev => {
        // prevent things behind the toolbar from being clicked
        ev.stopPropagation();

        // stops navigation
        ev.preventDefault();
      }}
    >
      <AddToFavouritesButton
        type="products"
        itemId={itemId?.toString()}
        child={(onClick, _isLoading, isFavourited) => {
          const tooltip = isFavourited ? 'SAVED TO FAVOURITES' : 'SAVE TO FAVOURITES';
          const icon = isFavourited ? HeartIcon : HeartOutlineIcon;
          const className = isFavourited ? bem('icon-heart-saved') : bem('icon-heart');
          return (
            <Tooltip text={tooltip}>
              <a onClick={onClick}>
                <SvgIcon className={className} size="xl" Icon={icon} key={className} />
              </a>
            </Tooltip>
          );
        }}
      />

      {(addToWishlist || !hasRemoveFromCurrentPageButton) && (
        <AddToWishlistPopover
          productId={itemId}
          inkindRouteId={inkindRouteId}
          child={onClick => (
            <Tooltip text={addToWishlist ? 'ADD TO WISHLIST' : 'REMOVE FROM WISHLIST'}>
              <a className={bem('icon-plus-btn')} onClick={onClick}>
                <SvgIcon className={bem('icon-plus')} size="2xl" Icon={addToWishlist ? PlusIcon : MinusIcon} />
              </a>
            </Tooltip>
          )}
        />
      )}

      {hasRemoveFromCurrentPageButton && (
        <RemoveFromWishlistButton
          inkindRouteId={inkindRouteId}
          itemId={itemId}
          child={onClick => {
            return (
              <Tooltip text="REMOVE PRODUCT">
                <a onClick={onClick}>
                  <SvgIcon className={bem('icon-trash')} size="xl" Icon={TrashIcon} />
                </a>
              </Tooltip>
            );
          }}
        />
      )}
    </div>
  );
}
