import type { HorVerLayout } from '@gik/core/types/layouts';
import { LayoutTypes } from '@gik/core/types/layouts';
import type { MouseEventProps, UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import { PageAvatar } from '@gik/ui/Avatar';
import { HTMLParser } from '@gik/ui/HTMLParser';
import { KeepProportions } from '@gik/ui/KeepProportions';
import React from 'react';

export type InkindPageCardBaseProps = {
  title: string;
  imageUrl: string;
  location?: string;
  layout?: HorVerLayout;
  admin?: boolean;
  toolbar?: React.ReactNode;
} & UIComponent &
  MouseEventProps;

const blockName = 'inkind-page-card-base';

export function InkindPageCardBase({
  layout,
  admin,
  toolbar,
  imageUrl,
  title,
  location,
  className,
  ...otherProps
}: InkindPageCardBaseProps): JSX.Element {
  const bem = useBemCN(blockName);

  return (
    <div {...bem(null, [{ admin }, { row: layout === LayoutTypes.horizontal }], className)} {...otherProps}>
      <KeepProportions proportion={1}>
        <PageAvatar {...bem('image')} imageUrl={imageUrl} circle={false} />
      </KeepProportions>

      <section {...bem('content')}>
        <section {...bem('title')}>
          <HTMLParser rawHtml={title.length > 75 ? title.substr(0, 72) + '...' : title} />
        </section>

        <section {...bem('location')}>{location || <>&nbsp;</>}</section>
      </section>

      {toolbar}
    </div>
  );
}
