import Link from '@/components/Link';
import type { Hit } from '@algolia/client-search';
import type { InkindPageAPIModel } from '@gik/core/models/gik/InkindPage';
import { useInkindCan } from '@gik/core/store/permissions';
import { useUserStore } from '@gik/core/store/UserStore';
import type { HorVerLayout } from '@gik/core/types/layouts';
import type { UIComponent } from '@gik/core/types/UI';
import { useBemCN } from '@gik/core/utils/bemBlock';
import withComponentErrorBoundary from '@gik/core/utils/withComponentErrorBoundary';
import { isInkindPageOrganizer, isInkindPageOwner } from '@gik/inkind-page/utils/inkindPagePermissions';
import isInkindPageRecipientInfoComplete from '@gik/inkind-page/utils/isInkindPageRecipientInfoComplete';
import type { InkindAlgoliaObject } from '@gik/search/components';
import { HoverAnimation } from '@gik/ui/HoverAnimation';
import { useInkindAPIOrAlgolia } from '@gik/user-profile/hooks/useInkindAPIOrAlgolia';
import React from 'react';
import { InkindPageTileToolbar } from '../InkindPageTileToolbar/InkindPageTileToolbar';
import { InkindPageCardBase } from './InkindPageCardBase';

export type InkindPageCardProps = {
  inkindPage: InkindPageAPIModel | Hit<InkindAlgoliaObject>;
  onRemove(
    inkindPageOrAlgolia: InkindPageAPIModel | Hit<InkindAlgoliaObject>,
    canEdit: boolean,
    isOrganizer: boolean
  ): void | Promise<void>;
  noToolbar?: boolean;
  layout?: HorVerLayout;
} & UIComponent;

const blockName = 'inkind-page-card';

function InkindPageCardComp({
  onRemove,
  inkindPage: inkindPageOrAlgolia,
  noToolbar,
  layout,
}: InkindPageCardProps): JSX.Element {
  const bem = useBemCN(blockName);

  const { ownerId, slug, title, imgSrc, routeId, groupId, location, organizers } =
    useInkindAPIOrAlgolia(inkindPageOrAlgolia);
  const canEdit = useInkindCan('manage', routeId, groupId);
  const userId = useUserStore(store => store.id);
  const isOwner = isInkindPageOwner(ownerId, userId);
  const isOrganizer = isInkindPageOrganizer({ organizers }, userId);

  const onClickRemove = async function (page: InkindPageAPIModel | Hit<InkindAlgoliaObject>) {
    await onRemove(page, isOwner, isOrganizer);
  };

  return (
    <HoverAnimation {...bem('hover-wrapper')}>
      <div>
        <Link href={`/inkinds/${routeId}/${slug}`}>
          <InkindPageCardBase
            admin={canEdit}
            toolbar={!noToolbar && <InkindPageTileToolbar inkindPage={inkindPageOrAlgolia} onRemove={onClickRemove} />}
            imageUrl={imgSrc}
            title={title}
            location={isInkindPageRecipientInfoComplete(inkindPageOrAlgolia) && location}
            layout={layout}
          />
        </Link>
      </div>
    </HoverAnimation>
  );
}

export const InkindPageCard = withComponentErrorBoundary(InkindPageCardComp);
